import React, { useState, useEffect, useDebugValue } from "react";
import axios from "axios";

function UserFormFetchData(){
    const [values,setValues] = useState({
            startsWith:'',
            endsWith:'',
            contains:'',
            numberOfLetters:''
        }
    );
    const [words,setWords] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    
    useEffect(()=>{
        var request=`https://api.fillon.me/Words?Prefix=${values.startsWith}&Suffix=${values.endsWith}&Letters=${values.contains}&Length=${values.numberOfLetters}&PageNumber=${pageNumber}`;
        console.log(request);
        axios.get(request)
        .then(result=>{
           console.log(result.data.data);

           if(result.data.data.length!=0){
               setWords(result.data.data);
           }
           else{
               setWords(['Nuk ka rezultate!']);
           }

           console.log(words)
        })
        .catch(err=>{
            console.log(err);
        })

    },[submitted,pageNumber]);

    // useEffect(()=>{
    //     document.getElementById("myModal").addEventListener('scroll',handleScroll)
    //     console.log(document.getElementById("myModal"))
    // })

    // const handleScroll = (event)=>{
    //     console.log(document.getElementById("myModal").scrollTop)
    //     if(document.getElementById("myModal").scrollTop>pageNumber*50)
    //         setPageNumber(pageNumber+1)
    // }

    const onChangeStartsWith = (event) => {
        setValues({
            ...values,
            startsWith: event.target.value
        })
    }

    const onChangeEndsWith = (event) => {
        setValues({
            ...values,
            endsWith: event.target.value
        })
    }

    const onChangeNumberOfLetters = (event) => {
        setValues({
            ...values,
            numberOfLetters:event.target.value
        })
    }

    const onChangeContains = (event) => {
        setValues({
            ...values,
            contains:event.target.value
        })
    }

    const onSubmitHandle = (event) => {
        event.preventDefault();
        setPageNumber(1)
        setSubmitted(!submitted);
    }

    return(
        <div class="container">
            <form class="form-style-8" onSubmit={onSubmitHandle}>
                <div>
                    <input type="text" placeholder="Fillon me..." value={values.startsWith} onChange={onChangeStartsWith}/>
                </div>
                <div>
                    <input type="text" placeholder="Mbaron me..." value={values.endsWith} onChange={onChangeEndsWith}/>
                </div>
                <div>
                    <input type="text" placeholder="Permban kudo ne fjale..." value={values.contains} onChange={onChangeContains}/>
                </div>
                <div>
                    <input type="number" placeholder="Shkronjat..." value={values.numberOfLetters} onChange={onChangeNumberOfLetters}/>
                </div>
                <button class="button-style" id="myBtn" type="submit">Kerko</button>
            </form>
            <div id="myModal" class="modal">
                <div class="modal-content">
                    <div class="modal-header">
                    <span class="close">&times;</span>
                    </div>
                    <div class="modal-body">
                        <div class="flex-container">
                        {
                            words.map(word=>(
                                <div class="word-container">{word}</div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserFormFetchData