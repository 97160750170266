import logo from './logo.svg';
import './App.css';
import './modal.js'
import { Helmet } from 'react-helmet'
import UserFormFetchData from './components/UserFormFetchData';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fillon me</title>
      </Helmet>
      <div class="content">
        <h2 class="text_shadows">fillon me.</h2>
      </div>
      <UserFormFetchData/>
    </div>
  );
}

export default App;
